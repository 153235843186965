import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import moment from 'moment'
import DateSelectCtl from '../InputCtls/DateSelectCtl';
import DateTimeSelectCtl from '../InputCtls/DateTimeSelectCtl';
import TextInputCtl from '../InputCtls/TextInputCtl';
import NumberInputCtl from '../InputCtls/NumberInputCtl';
import CheckBoxInputCtl from '../InputCtls/CheckBoxInputCtl';
import DropDownListCtl from '../InputCtls/DropDownListCtl';
import SignatureCtl from '../InputCtls/SignatureCtl';
import { MilestoneItem, MilestoneLineItem, UserInfo } from '../../models/models';
import { mapStateToProps, mapDispatchToProps } from '../../redux/reduxActions';
import { PageViewTypeEnum, InputCtlViewMode, InputCtlDdlDataSource } from '../../models/enums';
import { addPageViewClass } from '../../functions/generalFunctions'

import '@progress/kendo-theme-default/dist/all.css'
import './MilestoneLinesGrid.css';
import { CustomWindow } from '../../models/custom.window';
import { Button } from '@progress/kendo-react-buttons';

declare let window: CustomWindow;

declare function invokeEnableZebraScannerAction(callBackFunc: string, callBackId: string): void;
declare function invokeDisableZebraScannerAction(): void;
declare function invokeOpenCameraScannerAction(callBackFunc: string, callBackId: string): void;

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type MilestoneLinesGridProps = PropsFromRedux & {
    milestone: MilestoneItem;
    editable: boolean;
    onUpdate?: (e: MilestoneItem) => void;
}


interface MilestoneLinesGridState {
    milestone: MilestoneItem;
    focussedCtrl: any;
    barcodeTarget: any;
}
interface tcbObjSignature {
    TextValue: string,
    SigValue: string
}


class MilestoneLinesGrid extends React.Component<MilestoneLinesGridProps, MilestoneLinesGridState> {
    public static defaultProps = {
        editable: false
    }

    constructor(props: MilestoneLinesGridProps) {
        super(props);

        this.state = {
            milestone: this.props.milestone,
            focussedCtrl: null,
            barcodeTarget: null
        }

        window.milestonelineRef = this;

    }

    componentDidUpdate(prevProps: MilestoneLinesGridProps) {
    }

    componentDidMount() {

        if (typeof invokeDisableZebraScannerAction !== 'undefined') {
            invokeDisableZebraScannerAction();
        }

        if (typeof invokeEnableZebraScannerAction !== 'undefined') {

            invokeEnableZebraScannerAction('window.milestonelineRef.processScan', "MilestoneLinesGrid");

        }
    }

    componentWillUnmount() { 
        if (typeof invokeDisableZebraScannerAction !== 'undefined') {
            invokeDisableZebraScannerAction();
        }
        if (typeof invokeEnableZebraScannerAction !== 'undefined') {
            invokeEnableZebraScannerAction('window.milestonelineRef.processScan', "MilestoneLinesGrid");
        }

    }

    updateMilestonScanDate = (scanDate?: Date) => {
        let ms = this.state.milestone;
        ms.scanDate = scanDate;
        this.setState({ milestone: ms });
        if (this.props.onUpdate) {
            this.props.onUpdate(ms);
        }

    }

    cameraScanClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (typeof invokeOpenCameraScannerAction !== 'undefined') {
            invokeOpenCameraScannerAction('window.milestonelineRef.processScan', "MilestoneLinesGrid");
        }
 
    }

    processScan = (callBackId: string, scannedCode: string) => {

        // Verify the callback is for this function
        if (callBackId !== "MilestoneLinesGrid")
            return;

        if (scannedCode.length === 0) {
            return;
        }

        //Search barcode table for match
        let url = this.props.userInf.currProject.apiUrl + '/api/TextFromBarcode?barcodeStr=' + encodeURI(scannedCode);

        fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => resp.json())
            .then(res => {

                switch (res.callStatus) {
                    //Search API uses callStatus to indicate of barcode found
                    case "OK":

                        if (this.state.focussedCtrl && this.state.focussedCtrl instanceof TextInputCtl) {
                            (this.state.focussedCtrl as TextInputCtl).setValue(res.stringResponse);
                        }


                        else {
                            if (this.state.barcodeTarget) {


                                if (this.state.milestone) {

                                    var thisMilestone = this.state.milestone;

                                    let found = thisMilestone.milestoneLines.find(x => x.milestoneLineId === this.state.barcodeTarget);

                                    if (found) {

                                        if (found.attribClassCode?.toLowerCase() === "signature") {

                                            let textField = document.getElementById("sigtextField") as HTMLInputElement;
                                            if (textField !== null) {
                                                textField.value = res.stringResponse;
                                            }


                                            if (found.milestoneLineAnswer) {

                                                let obj: tcbObjSignature = JSON.parse(found.milestoneLineAnswer);
                                                let sigValue = obj.SigValue;

                                                let output: any = {
                                                    "TextValue": res.stringResponse,
                                                    "SigValue": sigValue
                                                };

                                                let json = JSON.stringify(output);

                                                found.milestoneLineAnswer = json;
                                            }
                                            else {
                                                let output: any = {
                                                    "TextValue": res.stringResponse,
                                                    "SigValue": null
                                                };

                                                let json = JSON.stringify(output);

                                                found.milestoneLineAnswer = json;
                                            }

                                        }
                                        else {
                                            if (found.dataType === "List") {

                                                let outerDiv = document.getElementById(this.state.barcodeTarget) as HTMLInputElement;
                                                if (outerDiv !== null) {
                                                    let textField = outerDiv.children[0].children[0].children[0] as HTMLInputElement;
                                                    if (textField !== null) {
                                                        textField.innerHTML = res.stringResponse;
                                                    }
                                                }

                                                found.milestoneLineAnswer = res.stringResponse;
                                            }
                                            else {

                                            }
                                            found.milestoneLineAnswer = res.stringResponse;
                                        }

                                        this.setState({ milestone: thisMilestone });

                                    }
                                }


                            }
                        }
                        break;

                    case "NOTFOUND":
                        //do nothing - no match with barcode
                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                        this.props.updateUserInfo(uInf);

                        break;

                    default:

                }
            })
            .catch( _ => {
                //var x = 'Error Searching! - ' + err.toString()
            }
            );


    }


    updateDefaultMilestoneLineItemVal = (milestoneLineId: string, dataVal: string | null, svm?: (newMode: InputCtlViewMode) => void) => {
        let ms = this.state.milestone;
        let rw = ms.milestoneLines.find((x) => x.milestoneLineId === milestoneLineId)!;
        rw.milestoneLineAnswer = dataVal;

        this.setState({ milestone: ms });
        if (this.props.onUpdate) {
            this.props.onUpdate(ms);
            if (svm)

                if (rw.attribClassCode?.toLowerCase() === "signature") {
                    svm(InputCtlViewMode.Edit);
                }
                else {
                    svm(InputCtlViewMode.View);
                }

        }
    }

    updateMilestoneLineBooleanField = (milestoneLineId: string, e: Boolean | null,) => {
        if (e) {
            let boolStr: string = 'false';
            if (e)
                boolStr = 'true';
            this.updateDefaultMilestoneLineItemVal(milestoneLineId, boolStr);
        } else {
            this.updateDefaultMilestoneLineItemVal(milestoneLineId, null);
        }
    }

    updateMilestoneLineDateField = (milestoneLineId: string, e: Date | undefined) => {
        if (e) {
            let dateStr: string = moment(e).format("YYYY-MM-DD");
            this.updateDefaultMilestoneLineItemVal(milestoneLineId, dateStr);
        } else {
            this.updateDefaultMilestoneLineItemVal(milestoneLineId, null);
        }
    }

    updateMilestoneLineNumberField = (milestoneLineId: string, e: number | null,) => {
        if (e != null) {
            this.updateDefaultMilestoneLineItemVal(milestoneLineId, e.toString());
        } else {
            this.updateDefaultMilestoneLineItemVal(milestoneLineId, null);
        }
    }

    handleFocus = (target: any) => {
        alert(target.toString());
        this.setState({ focussedCtrl: target });
    }

    handleSigFocus = (barcodeTarget: string) =>
    {
        this.setState({ barcodeTarget: barcodeTarget });
    }

    //Render Functions


    renderMilestoneScanDateRow = () => {
        let cn = "mslDescCol" + addPageViewClass(this.props.pageInf);

        if (this.state.milestone.includeTime) {
            return (
                <tr>
                    <td className={cn}>Date for this Scan</td>
                    <td><DateTimeSelectCtl 
                        mode={InputCtlViewMode.Select} 
                        valueDate={this.state.milestone.scanDate} 
                        onUpdate={(e) => this.updateMilestonScanDate(e)} />
                        </td>
                </tr>)
        } else {
            return (
                <tr>
                    <td className={cn}>Date for this Scan</td>
                    <td><DateSelectCtl mode={InputCtlViewMode.Select} valueDate={this.state.milestone.scanDate} onUpdate={(e) => this.updateMilestonScanDate(e)} /></td>
                </tr>)
        }


    }

    renderMilestoneLineDescCell = (mli: MilestoneLineItem) => {
        let cn = "mslDescCol" + addPageViewClass(this.props.pageInf);
        return (
            <td key={mli.milestoneLineId} className={cn}>
                {mli.milestoneLineDesc}
                {mli.responseRequired && <span className="requiredIndicatorSpan" > *</span>}
            </td>
        );


    }

    renderMilestoneLineValueCell = (itm: MilestoneLineItem) => {
        let retCell: JSX.Element;
        let cn = "mslAnswCol" + addPageViewClass(this.props.pageInf);

        if (!this.props.editable ||
            (this.props.pageInf.pageViewMode !== PageViewTypeEnum.Browser && itm.isReadOnlyPDA) ||
            (this.props.pageInf.pageViewMode === PageViewTypeEnum.Browser && itm.isReadOnlyWeb) ||
            (this.props.pageInf.pageViewMode === PageViewTypeEnum.Tablet && itm.isReadOnlyWeb) ||
            itm.isSystemData) {

            if (itm.isSystemData && !itm.isSystemDataFetched) {
                retCell = <td className={cn}>Data loaded automatically on Scan</td>
            } else {

                switch (itm.dataType.toLowerCase()) {
                    case "signature":
                        retCell = <td className={cn}>
                            <SignatureCtl
                                value={itm.milestoneLineAnswer}
                                mode={InputCtlViewMode.ReadOnly}
                                readOnlyAfterSave={true}
                            /></td>

                        break;

                    default:
                        if (itm.milestoneLineAnswer && itm.milestoneLineAnswer.length > 0)
                            retCell = <td className={cn}>{itm.milestoneLineAnswer}</td>
                        else
                            retCell = <td className={cn}>No Value</td>
                }
            }
        } else {

            switch (itm.dataType.toLowerCase()) {

                case "boolean":
                    let chkVal: boolean | null = null;
                    if (itm.milestoneLineAnswer) {
                        if (itm.milestoneLineAnswer.toLowerCase() === "true")
                            chkVal = true;
                        else
                            chkVal = false;
                    }


                    retCell = <td className={cn}>
                        <CheckBoxInputCtl key={itm.milestoneLineId}
                            value={chkVal}
                            onUpdate={(e) => this.updateMilestoneLineBooleanField(itm.milestoneLineId, e)}
                        />
                    </td>
                    break;

                case "date":

                    let defVal: Date | undefined;
                    let tmp = moment(itm.milestoneLineAnswer);
                    if (!tmp.isValid()) {
                        tmp = moment(itm.milestoneLineAnswer, "YYYY-MM-DD");
                        if (!tmp.isValid()) {
                            tmp = moment(itm.milestoneLineAnswer, "DD-MM-YYYY");
                        }
                    }
                    if (tmp.isValid()) {
                        defVal = tmp.toDate();
                    }
                    retCell = <td className={cn} >
                        <DateTimeSelectCtl 
                            valueDate={defVal} 
                            onUpdate={(e) => this.updateMilestoneLineDateField(itm.milestoneLineId, e)} 
                            />

                        </td>


                    break;

                case "decimal":
                case "number":

                    let numVal: number | null = null;
                    if (itm.milestoneLineAnswer && !isNaN(parseFloat(itm.milestoneLineAnswer)))
                        numVal = parseFloat(itm.milestoneLineAnswer)

                    retCell = <td className={cn}>
                        <NumberInputCtl mode={InputCtlViewMode.Select} key={itm.milestoneLineId}
                            value={numVal}
                            onUpdate={(e) => this.updateMilestoneLineNumberField(itm.milestoneLineId, e)}
                        />
                    </td>
                    break;
                case "list":

                    retCell = <td className={cn}>
                        <DropDownListCtl
                            keyProp={itm.milestoneLineId}
                            mode={InputCtlViewMode.Select}
                            dataSource={InputCtlDdlDataSource.AttrCl}
                            selectedValue={itm.milestoneLineAnswer}
                            lookupKey={itm.attribClassId.toString()}
                            onFocus={(utv?: () => void) => this.handleSigFocus(itm.milestoneLineId)}
                            onSelectChange={(e) => this.updateDefaultMilestoneLineItemVal(itm.milestoneLineId, e.value)}
                        />
                    </td>
                    break;

                case "string":
                default:

                    switch (itm.attribClassCode.toLowerCase()) {
                        case "signature":
                            retCell = <td className={cn}>
                                <SignatureCtl
                                    keyProp={itm.milestoneLineId}
                                    value={itm.milestoneLineAnswer}
                                    mode={InputCtlViewMode.Edit}
                                    readOnlyAfterSave={false}
                                    onFocus={(utv?: () => void) => this.handleSigFocus(itm.milestoneLineId)}
                                    onUpdate={(e: string | null, svm?: (newMode: InputCtlViewMode) => void) => this.updateDefaultMilestoneLineItemVal(itm.milestoneLineId, e, svm)}
                                /></td>

                            break;

                        default:

                            retCell = <td className={cn}>
                                <TextInputCtl key={itm.milestoneLineId}
                                    mode={InputCtlViewMode.View}
                                    value={itm.milestoneLineAnswer}
                                    style={{ width: "100%" }}
                                    onFocus={this.handleFocus}
                                    onUpdate={(e: string, svm?: (newMode: InputCtlViewMode) => void) => this.updateDefaultMilestoneLineItemVal(itm.milestoneLineId, e, svm)}
                                />
                            </td>
                            break;


                    }
            }
        }
        return retCell;
    }
    renderMilestoneTableRow = (e: MilestoneLineItem, i: number) => {
        let cn = "mslDescRow" + addPageViewClass(this.props.pageInf) + ((i % 2) > 0 ? " alt" : "");
        return (<tr className={cn} key={e.milestoneLineId}>
            {this.renderMilestoneLineDescCell(e)}
            {this.renderMilestoneLineValueCell(e)}
        </tr>);
    }


    render() {

        let mlis: MilestoneLineItem[] = [];
        switch (this.props.pageInf.pageViewMode) {
            case PageViewTypeEnum.Mobile:
                mlis = this.state.milestone.milestoneLines.filter(x => x.isVisiblePDA);
                break;
            case PageViewTypeEnum.Browser:
            case PageViewTypeEnum.Tablet:
                mlis = this.state.milestone.milestoneLines.filter(x => x.isVisibleWeb);
                break;
        }

        return (


            <div data-component="milestonelineRef">
                <Button onClick={this.cameraScanClick} title="Scan barcode using Camera" ><span className="k-icon k-font-icon k-i-qr-code-outline srchIconsBtn" /></Button>

                <table id="mslLinesTable">
                    <thead>
                        {this.renderMilestoneScanDateRow()}
                    </thead>
                    <tbody>
                        {mlis.map((e, i) => { return (this.renderMilestoneTableRow(e, i)) })}
                    </tbody>
                </table>
            </div>
        );

    }
}

export default connector(MilestoneLinesGrid);
